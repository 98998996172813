import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/dispatch",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dispatch",
          name: "dispatch",
          component: () => import("@/view/pages/Dispatch.vue"),
        },
        {
          path: "/cars",
          name: "cars",
          component: () => import("@/view/pages/Cars.vue"),
        },
        {
          path: "/customers",
          name: "customers",
          component: () => import("@/view/pages/Customers.vue"),
        },
        {
          path: "/active-orders",
          name: "ActiveOrders",
          component: () => import("@/view/pages/ActiveOrders.vue"),
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/Auth.vue"),
      meta: {
        anonymous: true,
      },
    },
    {
      path: "*",
      redirect: "/404",
      meta: {
        anonymous: true,
      },
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue"),
      meta: {
        anonymous: true,
      },
    }
  ],
});
