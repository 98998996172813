import axios from "axios";
import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

import { ApiAddressesService } from "./api.addresses.service";
import { ApiAuthService }      from "./api.auth.service";
import { ApiCarsService }      from "./api.cars.service";
import { ApiDriversService }   from "./api.drivers.service";
import { ApiCustomersService } from "./api.customers.service";
import { ApiOrdersService }    from "./api.orders.service";
import { ApiSettingsService }  from "./api.settings.service";
import { ApiRelayService }     from "./api.relay.service";

export default class ApiService {
  constructor(endpoint) {
    if (endpoint.substr(-1) !== "/") {
      endpoint += "/";
    }

    const http = axios.create({
      baseURL: endpoint,
      withCredentials: true,
    });

    const reconnectIntervals = [
      0,
      1  * 1000,
      2  * 1000,
      3  * 1000,
      5  * 1000,
      10 * 1000,
      15 * 1000,
      30 * 1000,
      45 * 1000,
      60 * 1000
    ];

    const hubOptions = {
      transport: HttpTransportType.WebSockets,
    };

    this.hub = new HubConnectionBuilder()
      .withUrl(endpoint + "hubs/dispatch", hubOptions)
      .withAutomaticReconnect(reconnectIntervals)
      .build();

    this.hub.keepAliveIntervalInMilliseconds = 2.5 * 1000;
    this.hub.serverTimeoutInMilliseconds     = 2 * this.hub.keepAliveIntervalInMilliseconds;

    this.connection = {
      state: new Subject(),
      events: {
        connecting:    new Subject(),
        connected:     new Subject(),
        disconnecting: new Subject(),
        disconnected:  new Subject(),
        reconnecting:  new Subject(),
        reconnected:   new Subject(),
      },
    };

    this.connection.state.subscribe(state => {
      switch(state) {
        case HubConnectionState.Connecting:
          this.connection.events.connecting.next();
          break;

        case HubConnectionState.Connected:
          this.connection.events.connected.next();
          break;

        case HubConnectionState.Disconnected:
          this.connection.events.disconnected.next();
          break;

        case HubConnectionState.Disconnecting:
          this.connection.events.disconnecting.next();
          break;

        case HubConnectionState.Reconnecting:
          this.connection.events.reconnecting.next();
          break;
      }
    });

    var reconnectingNotification = null;

    this.hub.onreconnecting(() => {
      this.connection.state.next(this.hub.state);

      reconnectingNotification = notify.warning("Conexiunea la server a fost întreruptă.<br />Se reconectează...", -1);
    });

    this.hub.onreconnected(() => {
      this.connection.state.next(this.hub.state);
      this.connection.events.reconnected.next();

      notify.success("Conexiunea la server a fost restabilită.");

      if (reconnectingNotification) {
        reconnectingNotification.close();
        reconnectingNotification = null;
      }
    });

    this.hub.onclose(() => {
      this.connection.state.next(this.hub.state);

      notify.error("Conexiunea la server este întreruptă.", -1);

      if (reconnectingNotification) {
        reconnectingNotification.close();
        reconnectingNotification = null;
      }
    });

    this.addresses = new ApiAddressesService(this.hub);
    this.auth      = new ApiAuthService(this.hub, http);
    this.cars      = new ApiCarsService(this.hub, http);
    this.customers = new ApiCustomersService(this.hub, http);
    this.drivers   = new ApiDriversService(this.hub, http);
    this.orders    = new ApiOrdersService(this.hub, http);
    this.settings  = new ApiSettingsService(this.hub, http);
    this.relay     = new ApiRelayService(this.hub);
  }

  async connect() {
    try {
      this.connection.state.next(HubConnectionState.Connecting);

      await this.hub.start();

      this.connection.state.next(this.hub.state);
    } catch {
      this.connection.state.next(this.hub.state);
    }
  }
}
