import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiOrdersService {
  /**
   * Creates a new instance of the API cars service.
   * @param {HubConnection} hub The SignalR hub connection to use.
   */
  constructor(hub, axios) {
    this.axios = axios;

    this.events = {
      new:       new Subject(),
      confirmed: new Subject(),
      cancelled: new Subject(),
      late: new Subject(),
      arrived: new Subject(),
      completed: new Subject(),

      hidden: new Subject(),
    };

    this.hub = hub;

    this.hub.on("Orders.New", order =>
      this.events.new.next(order)
    );

    this.hub.on("Orders.Confirmed", (id, car, duration, confirmed) =>
      this.events.confirmed.next({ id, car, duration, confirmed, status: 'confirmed' })
    );

    this.hub.on("Orders.Cancelled", (id, reason, cancelled) =>
      this.events.cancelled.next({ id, reason, cancelled, status: 'cancelled' })
    );

    this.hub.on("Orders.Late", (id, duration) => {
      this.events.late.next({ id, duration, status: 'late' })
    });

    this.hub.on("Orders.Arrived", (id, arrived) => {
      this.events.arrived.next({ id, arrived, status: 'arrived' })
    });

    this.hub.on("Orders.Completed", (id, arrived) => {
      this.events.completed.next({ id, arrived, status: 'completed' })
    });

    this.hub.on("Orders.Hidden", (id, hiddenBy) =>
      this.events.hidden.next({ id, hiddenBy })
    );
  }

  /**
   * Retrieves all active orders.
   *
   * @param {Boolean} allUsers Whether to retrieve orders sent by all dispatch users.
   */
  async list(allUsers) {
    try {
      const response = await this.hub.invoke("Orders.List", !!allUsers);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea comenzilor în curs.");

      throw newApiError(error);
    }
  }

  /**
   * Cancels a specified order.
   *
   * @param {String} orderId The ID of the order to cancel.
   */
  async cancel(orderId) {
    try {
      const response = await this.hub.invoke("Orders.Cancel", orderId);

      if (response && response.error) {
        throw newApiError(response);
      }
    } catch (error) {
      notify.error("Eroare la anularea comenzii.");

      throw newApiError(error);
    }
  }

  /**
   * Hides a specified order.
   *
   * @param {String} orderId The ID of the order to hide.
   */
  async hide(orderId) {
    try {
      const response = await this.hub.invoke("Orders.Hide", orderId);

      if (response && response.error) {
        throw newApiError(response);
      }
    } catch (error) {
      notify.error("Eroare la ascunderea comenzii.");

      throw newApiError(error);
    }
  }

  /**
   * Submits a new order.
   *
   * @returns New order details.
   */
  async new(request) {
    try {
      const response = await this.hub.invoke("Orders.New", request);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      throw newApiError(error);
    }
  }

  /**
   * Returns recent orders from the specified phone number.
   * @param {String} phone The phone number to get recent orders for.
   */
  async recent(phone) {
    try {
      const response = await this.hub.invoke("Orders.Recent", phone);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea comenzilor recente.");

      throw newApiError(error);
    }
  }

  async activeOrders(search) {
    try {
      const response = await this.axios.get("orders/active", search);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea comenzilor recente.");

      throw newApiError(error);
    }
  }
}
