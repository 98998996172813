
const orderStatusMappings = {
  new:      "sent",
  bidding:  "sent",
  station:  "sent",
  dispatch: "sent",

  confirmed: "confirmed",
  late:      "confirmed",
  arrived:   "confirmed",
  completed: "confirmed",

  cancelled: "cancelled",
};

export function mapOrder(order) {
  const mapped = {
    id: order.id,

    created:   order.created,
    confirmed: order.confirmed,
    arrived:   order.arrived,
    completed: order.completed,
    cancelled: order.cancelled,

    status: orderStatusMappings[order.status],
    originalStatus: order.status,

    address: order.address,
    notes:   order.notes,

    customer: order.customer,

    car: order.car,

    dispatch: order.dispatch,

    duration: order.duration,
    reason:   order.reason,
  };

  if (order.multiple) {
    mapped.multiple = {
      index: order.multiple.index + 1,
      count: order.multiple.count,
    };
  }

  return mapped;
}
